/// Authentication endpoint base URL
const AUTH_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_AUTH_URL_PROD
    : process.env.REACT_APP_AUTH_URL_DEV;

/// School services endpoint base URL
const SCHOOL_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SCHOOL_URL_PROD
    : process.env.REACT_APP_SCHOOL_URL_DEV;

const TEACHER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_TEACHER_URL_PROD
    : process.env.REACT_APP_TEACHER_URL_DEV;

const STUDENT_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STUDENT_URL_PROD
    : process.env.REACT_APP_STUDENT_URL_DEV;

/// Image upload service endpoint base URL
const IMAGE_UPLOAD_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_IMAGE_UPLOAD_URL_PROD
    : process.env.REACT_APP_IMAGE_UPLOAD_URL_DEV;

/// Cloudinary service endpoint
const CLOUDINARY_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_CLOUDINARY_URL_PROD
    : process.env.REACT_APP_CLOUDINARY_URL_DEV;

const urlSettings = {
  AUTH_URL,
  SCHOOL_URL,
  TEACHER_URL,
  STUDENT_URL,
  IMAGE_UPLOAD_URL,
  CLOUDINARY_URL,
};

export default urlSettings;
